<template>
  <div class="login">
    <!-- 登录 -->
    <section class="loginMain" v-if="!isSignLogin">

      <!-- 账号密码登录 -->
      <div class="main-content">
        <div class="project-name">
          <img :src="$currency.ossFileURL('j_logo')" alt="" />
          <span>采集助手数据大屏</span>
        </div>
        <h3 class="login-name">登录</h3>
        <el-form status-icon :model="codeForm" ref="ruleForm" action="" class="login-form" @keydown.enter.native="handleLogin">
          <div class="form-ipt">
            <el-form-item prop="username">
              <div class="label-txt">账号</div>
              <el-input v-model="codeForm.username" type="text" autocomplete="off" placeholder="请输入账号" clearable/>
            </el-form-item>
            <el-form-item prop="password">
              <div class="label-txt">密码</div>
              <el-input v-model="codeForm.password" type="password" autocomplete="off" placeholder="请输入密码" clearable/>
            </el-form-item>
          </div>
          <el-button
            round
            @click="handleLogin"
            class="login-btn"
            type="primary"
            :loading="isLoginLoading"
          >登录</el-button>
        </el-form>

        <div class="wechatCode">
          <el-popover class="test" placement="top" width="150" trigger="hover" popper-class="textCenter">
            <img :src="$currency.ossFileURL('wechatCode')" width=120 alt="图片加载未成功">
            <div class="wechat-btn" slot="reference">
              <img :src="$currency.ossFileURL('wechat')" alt="" />
              <el-link :underline="false" type="info" class="btn-name">微信小程序</el-link>
            </div>
          </el-popover>
        </div>

      </div>
    </section>

  </div>
</template>

<script>

import {notify} from "../../utils";

export default {
  name: "Login",
  data() {
    return {
      isSignLogin: false,             // true: 切换为手机号验证码页面, false: 切换为账号密码登录页面
      isVerifyCodeLogin: false,       // true: 切换为手机号验证码登录, false: 账号密码登录

      type: "password",
      codeForm: {
        phone: "",                    // 手机号
        verification_code: "",        // 验证码
        username: "",
        password: "",
      },
      count: 0,
      timer: null,
      btnTitle: "获取验证码",
      isDisabled: false,            // 是否可以点击

      isLoginLoading: false,

      disabledStyle: {
        color: "#3a8ee6",             // 倒数时字体颜色
      },
    }
  },

  mounted() {
    this.checkLogin();
  },

  methods: {

    // 验证是否已登录
    async checkLogin() {
      if (this.$auth.isLogin()) {
        await this.$store.dispatch("getUserInfo");
        if (this.$store.state.user.token !== "") {
          setTimeout(() => { this.$router.replace("/home") }, 1000);
          notify("success", "登录成功 !");
        } else {
          notify("error", "请先登录 !");
        }
        this.isLoginLoading = false;
      }
      this.isLoginLoading = false;
    },


    // 账号密码登录
    async handleLogin() {
      if (!this.codeForm.username) {
        this.$message.warning('请输入账号')
        return
      }
      if (!this.codeForm.password) {
        this.$message.warning('请输入密码')
        return
      }
      this.isLoginLoading = true;
      // 使用AES加密
      const AesPASS = this.$encrypt(this.codeForm.password);

      const {data, level} = await this.$api.signIn({
        username: this.codeForm.username,
        password: AesPASS,
      })
      if (level === 'success') {
        this.$auth.setToken(data);
        this.$store.commit('saveUserToken', data);
      }
      await this.checkLogin()
    },


    // region 获取验证码 暂不使用
    async getCode() {
      if (!this.isDisabled) {
        const res = await this.$api.getSmsCode({mobileNo: this.codeForm.phone});
        if (res.data.code === '1') {
          this.countTime();
          this.$message.success(res.data.message);
        }
      } else {
        this.$message.error("请等待60秒后再发送");
      }
    },
    // endregion


    // region 手机号验证码登录
    async staffLogin() {
      const params = {
        type: "phone",
        phone: this.codeForm.phone,
        verify_code: this.codeForm.verification_code
      };

      if (!/^1[3456789]\d{9}$/.test(params.phone)) {
        this.$message.warning("手机号格式不正确");
        return;
      }
      if (!/\d{4}$/.test(params.verify_code)) {
        this.$message.warning("验证码格式不正确");
        return;
      }

      const res = await this.$api.signIn(params);
      if (res.data.level === "success") {
        window.localStorage.setItem('user_token', res.data.data);
        window.localStorage.setItem("is_staff", "1");
        await this.checkLogin();
      }
    },
    // endregion

    // region 验证码倒计时
    countTime() {
      const time = 60;
      if (!this.timer) {
        this.count = time;
        this.isDisabled = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= time) {
            this.count -= 1;
          } else {
            this.isDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        },1000)
      }
    },
    // endregion

    /*handleCodeSubmit() {
      this.handleLogin();
    },*/

    // 注册页面切换为登录页面的手机验证码登录方式
    /*signPhoneLogin() {
      this.isVerifyCodeLogin = true;
      this.isSignLogin = false;
    },*/

    // 注册页面切换为登录页面的账号密码登录方式
    /*signPassLogin() {
      this.isVerifyCodeLogin = false;
      this.isSignLogin = false;
    },*/


  },
};
</script>

<style lang="scss" scoped>

$login-bg: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/login.png");

::v-deep .el-input--small .el-input__inner{
  -webkit-user-select:text !important;
}

.login {
  height: 100vh;
  background-image: $login-bg;
  background-size: cover;

  section.loginMain {
    width: 550px;
    position: fixed;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    -webkit-box-shadow: 0 4px 6px rgb(0 0 0 / 10%), 0 12px 20px rgb(38 38 38 / 12%);
    box-shadow: 0 4px 6px rgb(0 0 0 / 10%), 0 12px 20px rgb(38 38 38 / 12%);
    border-radius: 12px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    /*position: fixed;
    top: 100px;
    right: 50px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 680px;
    background-color: #fff;
    padding-bottom: 30px;
    border-radius: 20px;*/

    .main-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 410px;
      margin-top: 95px;
      padding-left: 34px;

      .project-name {
        width: 100%;
        height: 30px;
        margin: 0;
        line-height: 30px;
        color: #007457;
        font-size: 30px;
        display: flex;
        align-items: center;
        img {
          width: 46px;
          height: 44px;
        }
        span {
          margin-left: 20px;
          font-weight: bold;
        }
      }

      .login-name {
        height: 24px;
        padding-top: 60px;
        line-height: 24px;
        font-size: 24px;
        color: #333333;
        font-weight: 600;
      }

      .login-form {
        width: 100%;

        .form-ipt {
          position: relative;
          margin-top: 54px;

          .label-txt {
            color: #333333;
            font-size: 14px;
            padding-bottom: 10px;
          }

          input {
            /*box-sizing: border-box;
            width: 100%;
            height: 64px;
            margin-top: 16px;
            font-size: 12px;
            color: #9f9f9f;
            font-family: SimSun, sans-serif;
            border: none;
            outline: none;
            border-bottom: 2px solid #dedede;*/
          }

          /* 发送验证码 */
          .send-security-code {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 64px;
          }

        }

        .login-btn {
          display: block;
          width: 100%;
          height: 42px;
          margin: 40px auto 0;
          color: #fff;
          font-size: 16px;
          outline: none;
          border: none;
          cursor: pointer;
          border-radius: 26px;
          background-color: #007457;
        }
      }

    }

    .wechatCode {
      width: 554px;
      margin-top: 80px;

      .wechat-btn {
        display: flex;
        align-items: center;
        width: 190px;
        height: 40px;
        background-color: #f5f5f5;
        border-radius: 20px;
        cursor: pointer;

        img {
          display: block;
          width: 27px;
          height: 27px;
          margin: 0 29px 0 25px;
        }

        span {
          font-size: 12px;
          color: #808080;
        }
      }
    }
  }
}
</style>
<style scoped>

/deep/ span.el-link--inner {
  color: #9f9f9f9f;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  cursor: pointer;
}



.send-security-code /deep/ span.el-link--inner {
  font-size: 14px;
  color: #409EFF;
}

.send-security-code.is-disabled /deep/ span.el-link--inner {
  cursor: not-allowed !important;
}

.login-account /deep/ span.el-link--inner {
  color: #409EFF;
}

.header-logo .el-avatar {
  background-color: #fff;
}

.header-logo /deep/ div {
  width: 100% !important;
  height: 100% !important;
}

.form-ipt /deep/ .el-form-item:first-child {
  margin-top: 4px;
}

/deep/.form-ipt .el-input__inner:focus {
  border-color: #007457 !important;
}



.form-ipt /deep/ .el-input__inner {
  border: 1Px solid #F4F4F4;
  border-left: 10px solid #007457;
  background-color: #F4F4F4;
  height: 40Px;
}

.form-ipt /deep/ .el-input {
  border-radius: 0;
}
.form-ipt /deep/ .el-input .el-input__suffix .el-input__icon.el-input__validateIcon.el-icon-circle-check {
  color: #0abf53;
}

/deep/ .el-form-item.is-error .el-input__inner {
  border-color: #F56C6C;
}
</style>
<style scoped>



.el-select {
  width: 180px !important;
}

.select-work-msg .el-input__icon {
  width: 20px;
  height: 20px;
}

.select-work-msg .el-select__caret::before {
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  /*background: url('../../assets/image/login/select-on.png') no-repeat;*/
  background-size: 20px 20px;
}

.select-work-msg .el-input__suffix-inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.select-work-msg .el-select__caret {
  line-height: 0;
}

/deep/.is-disabled span.el-link-inner {
  cursor: not-allowed !important;
}

.el-select .el-input .el-input__inner::placeholder,
.el-select .el-input.is-disabled .el-input__inner::placeholder {
  color: #757575;
  text-align:center;
}

/* 谷歌 */
.el-select .el-input .el-input__inner::-webkit-input-placeholder,
.el-select .el-input.is-disabled .el-input__inner::placeholder {
  color: #757575;
  text-align:center;
}

/* 火狐 */
.el-select .el-input .el-input__inner:-moz-placeholder,
.el-select .el-input.is-disabled .el-input__inner::placeholder {
  color: #757575;
  text-align:center;
}

/*ie*/
.el-select .el-input .el-input__inner:-ms-input-placeholder,
.el-select .el-input.is-disabled .el-input__inner::placeholder {
  color: #757575;
  text-align:center;
}

.form-ipt .el-input .el-input__inner::placeholder {
  color: #757575;
}

</style>

